/* Custom fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  --background: #fff;
  --contrast: #000;
  --white: #fff;
  --black: #000;
}
/* Default CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.underline {
  text-decoration: underline;
  padding: 10px;
}
html {
  width: 100vw;
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  overflow-x: hidden;
}

body {
  font-family: 'Lato', sans-serif;
  width: 100vw;
  font-weight: 700;
  overflow-x: hidden;
  position: relative;
  background-color: var(--background);
  color: var(--contrast);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  line-height: normal;
}


/************ Navbar CSS ************/
nav.navbar {
  padding: 0px 40px;
  height: 60px;
  position: fixed;
  width: 90%;
  left: 5%;
  top: 20px;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background-color: transparent;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo{
  width: auto;
  height: 60px;
}

nav.navbar.scrolled {
  background-color: #fff;
}
/* Media query for smaller screens */
@media (max-width: 992px) {
  nav.navbar {
    padding: 0 10px;  /* Reduce padding even further for smaller screens */
    background-color: #fff; /* Set background color for small screens */
    height: auto;  /* Allow height to adjust automatically */
    font-weight: 600;
  }

  .logo {
    height: 60px;  /* Make the logo smaller on small screens */
  }

  nav.navbar .navbar-toggler {
    margin-left: auto; /* Ensure the toggler is aligned properly */
  }
}
nav.navbar a.navbar-brand {
  width: auto;
  text-align: left;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 600;
  color: var(--contrast);
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover{
  opacity: 1;
  color: #0701F6;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: var(--black);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #0701F6;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus,
nav.navbar .navbar-toggler {
  outline: none;
  box-shadow: none;
  border: none;
}


/* Dropdown Container */
.dropdown-container {
  padding: 10px 0px;
  padding-left: 20px;
  overflow: auto;
  position: fixed;
  z-index: 9999;
  width: 80%;
  left: 10%;
  top: 93px;
  margin: 1px;
  transition: 0.32s ease-in-out;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-weight: 600;
  animation: slideDown 0.5s ease-in-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply this class when the dropdown is closing */
.dropdown-container.closing {
  animation: slideUp 0.5s ease-in-out;
  opacity: 0;
}

/* Animation for closing */
@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.left{
  align-items: flex-start;
}

.dropdown-container .navbar-nav {
  display: flex; /* Use flex for nav items */
  flex-direction: column; /* Stack items in a column */
  margin-left: 10px;
}

.dropdown-icons{
  display: flex;
  margin-right: 50px;
  align-self: flex-start;
}

.dropdown-container.show {
  display: block; /* Show the dropdown when toggled */
  max-height: 300px; /* Maximum height to ensure links fit */
}

/* Links inside the dropdown */
.dropdown-container .navbar-link {
  color: var(--background);
  padding: 15px 20px; /* Padding for better spacing */
  font-size: 18px;
  opacity: 0.9;
  text-align: center; /* Center text */
  transition: color 0.3s ease-in-out; /* Smooth hover effect */
}

.dropdown-container .navbar-link:hover,
.dropdown-container .navbar-link.active {
  opacity: 1;
  color: var(--background);
}

/* Common button styles for dropdown */
.dropdown-button {
  color: var(--black); /* Set text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Adjust padding */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Add a transition effect */
}

/* Hover effect */
.dropdown-button:hover {
  color: #0701F6 !important;
}

/* Slideshow.css */
.slideshow-section {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  opacity: 1;
}
.central-image {
  display: flex;
  align-items: center; /* Center the logo image */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.logo-box {
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent black background */
  padding: 20px 30px; /* Padding around the logo and text */
  border-radius: 12px; /* Rounded corners for a softer look */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-text {
  font-size: 2rem; /* Adjust text size as needed */
  color: #6066B1; /* Text color */
  font-weight: 550;
  text-align: left; /* Align text to the left */
  padding: 0px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
  max-width: 300px; /* Restrict width to keep to two lines */
  line-height: 1.2; /* Tighter line spacing to control line breaks */
  white-space: nowrap; /* Prevent text from breaking into multiple lines */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}


.central-image img {
  max-width: 300px; /* Adjust this to control the size of the central image */
  height: auto;
  display: block;
}
.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 50px; /* Adjust based on your layout */
  width: 100%;
}

.down-arrow {
  display: flex;
  flex-direction: column; /* Stack arrows vertically */
  align-items: center;
  justify-content: center;
  position: relative;
  width: 60px;
  height: 120px; /* Height adjusted for two arrows */
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transition: transform 0.3s ease-in-out;
}
.down-arrow img {
  transform: scaleY(-1); /* Flips the image horizontally */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.down-arrow:hover img {
  animation: bounce 0.5s ease infinite; /* Makes the arrow bounce on hover */
}

/* Keyframes for the bounce effect */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0) scaleY(-1); /* Maintain horizontal flip */
  }
  50% {
    transform: translateY(-10px) scaleY(-1); /* Bounce up and maintain flip */
  }
}



/* Structural Engineering Section */
.structural-engineering-section {
  padding: 60px 40px;
  background-color: var(--black);
  display: flex;
  justify-content: center;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Text and Image Layout (2 columns) */
.text-and-images {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}

/* Text content on the left (column 1) */
.text-content {
  flex: 1;
  color: var(--white);
  padding-right: 20px;
  min-width: 600px;
}

.text-content h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.text-content p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Image Container */
.image-container {
  display: flex;
  justify-content: space-around;
  flex: 1;
  max-width: 600px;
}

.image {
  max-width: 40%;
  height: auto;
  border-radius: 8px;
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Counters Section (Centered below text and images) */
.counters-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
  flex-wrap: wrap;
}

.single-counter {
  text-align: center;
  flex: 1 1 30%;
  margin: 0 10px;
}

.counter-number {
  font-size: 4rem;
  color: var(--white);
  font-weight: 700;
  transition: transform 0.3s ease, font-size 0.3s ease;
  display: inline-block;
}

.counter-label {
  font-size: 1.2rem;
  color: var(--white);
  margin-top: 10px;
}

/* Hover effect for counters */
.single-counter:hover .counter-number {
  transform: scale(1.1);
}

/* Responsive Styles */
@media (max-width: 992px) {
  .text-and-images {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-content {
    padding-right: 0;
    width: 100%;
    margin-bottom: 20px;
    min-width: 300px;
  }

  .image-container {
    width: 100%;
    justify-content: center;
  }

  .image {
    max-width: 80%; /* Make images larger on mobile screens */
  }
}


@media (max-width: 480px) {
  .text-content h2 {
    font-size: 28px;
  }

  .text-content p {
    font-size: 16px;
  }

  .counter-number {
    font-size: 3rem;
  }

  .counter-label {
    font-size: 1rem;
  }

  .experience-counter {
    font-size: 1.5rem;
  }
}
/*services*/
.service-cards-section {
  padding: 60px 0;
  text-align: center;
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-gap: 20px; /* Spacing between cards */
  justify-items: center; /* Center items horizontally */
  margin-top: 30px;
}

@media (max-width: 992px) {
  .service-cards {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
}

@media (max-width: 768px) {
  .service-cards {
    grid-template-columns: 1fr; /* 1 column on mobile */
  }
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 350px;
  perspective: 1000px;
  cursor: pointer;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.flip-card-back {
  background-color: #333;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

h3 {
  font-size: 24px;
  margin-top: 20px;
}

.service-icon {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.flip-card-back p {
  font-size: 15px;
  text-align: center;
}
/* Reviews */
.review {
  position: relative;
  margin-bottom: 0;
  color: #000;
}

.review-bx {
  background: #82B6D8;
  text-align: center;
  padding: 40px;
  margin-top: 0px;
}

.review h2 {
  padding-top: 60px; /* Adjust the value as needed */
  font-size: 45px;
  font-weight: 700;
  position: relative;
}

.review p {
  color: #000;
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 0.5cm;
  margin: 12px 15px 14px 15px;
}

.review-slider {
  position: relative;
}

.react-multi-carousel-track {
  display: flex;
}

.react-multi-carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.react-multi-carousel-arrow--left {
  left: 10px;
}

.react-multi-carousel-arrow--right {
  right: 10px;
}



/************ contact Css ************/
.contact {
  padding-top: 100px; /* Adjust the value as needed */
  background-image: url(assets/img/contact-img.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 0;
  
  /* Flexbox centering */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Center the text content */
}
.contact form {
  display: flex; /* Make the form a flex container */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items */
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
  color: #fff;
}
.contact h3 {
	font-size: 35px;
	font-weight: 700;
  margin-bottom: 30px;
  color: #fff;
}
.contact form input{
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: var(--background);
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form textarea {
  width: 50%; /* Full width of its parent */
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: var(--background);
  padding: 18px 20px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--background);
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
  width: 50%;
}
.contact form button {
  font-weight: 700;
  color: var(--black);
  background-color: var(--background);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
  border-radius: 20px;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: var(--white);

}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
  border-radius: 17px;
}
.contact form button:hover::before {
  width: 100%;
}
/************ Footer Css ************/
.footer {
  background-color: #f8f9fa; /* Light background color */
  padding: 20px 0; /* Padding for top and bottom */
  width: 100%;
}

.footer-content {
  padding-bottom: 20px; /* Space between content and bottom */
}

.footer-images {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between the two images */
  margin-bottom: 10px; /* Add space between images and address */
}

.footer-logo {
  height: 80px; /* Adjust logo height */
  width: auto;
}

.footer-icon {
  height: 80px; /* Adjust size of second image */
  width: auto;
}

.footer-address {
  font-size: 14px;
  line-height: 1.6;
}

.footer-address p {
  margin: 0;
}

.footer-bottom {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.footer-bottom p {
  margin: 0;
  font-size: 12px;
  color: #666;
}

/* Mobile-specific adjustments */
@media (max-width: 576px) {
  .footer-images,
  .footer-address {
      text-align: center; /* Center align on mobile */
  }

  .footer-images {
      display: flex;
      flex-direction: column; /* Stack images vertically */
      align-items: center; /* Center the images horizontally */
      gap: 10px; /* Reduce gap between images */
      padding-bottom: 10px; /* Add space between images and address */
  }

  .footer-logo {
      width: 200px;
      height: auto;
  }

  .footer-icon {
      height: 60px; /* Reduce image height for mobile */
      width: auto;  /* Maintain aspect ratio */
  }

  .footer-address {
      font-size: 13px; /* Reduce font size slightly on mobile */
  }

  .footer p {
      font-size: 12px; /* Adjust font size for readability */
  }
}
/* Styling for the Info Page */
.info-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  padding-top: 100px;
  max-width: 1200px;
  margin: auto;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.hero-text {
  flex: 1;
}

.hero-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
}

.hero-text p {
  font-size: 1.2rem;
  color: #555;
}

.hero-image img {
  max-width: 500px;
  border-radius: 10px;
}

.services-section {
  margin-bottom: 50px;
}

.services-section h2 {
  text-align: center;
  font-size: 2rem;
  color: #2980b9;
  margin-bottom: 20px;
}

.info-service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.info-service-card {
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex: 1 1 calc(50% - 20px);  /* 3 cards per row on larger screens */

}
.info-service-card ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  color: #555;
}

.info-service-card li {
  margin-bottom: 8px;
  font-size: 1rem;
}
.info-service-card img {
  width: 100px;
  margin-bottom: 15px;
}

.info-service-card h3 {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 10px;
}

.info-service-card p {
  font-size: 1rem;
  color: #777;
}

.projects-section {
  margin-bottom: 50px;
}

.projects-section h2 {
  text-align: center;
  font-size: 2rem;
  color: #2980b9;
  margin-bottom: 20px;
}

.project-gallery img {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.project-gallery {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.project-gallery .flip-container {
  perspective: 1000px;
  width: 300px;
  height: 200px;
}

.project-gallery .flipper {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.project-gallery .flip-container:hover .flipper {
  transform: rotateY(180deg);
}

.project-gallery .front,
.project-gallery .back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  backface-visibility: hidden;
}

.project-gallery .front {
  transform: rotateY(0deg);
  z-index: 2;
}

.project-gallery .back {
  transform: rotateY(180deg);
  z-index: 1;
}
.contact-section {
  text-align: center;
  margin-bottom: 50px;
}

.contact-section h2 {
  font-size: 2rem;
  color: #2980b9;
  margin-bottom: 20px;
}

.contact-section p {
  font-size: 1.1rem;
  color: #555;
}

@media (max-width: 992px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-image img {
    max-width: 100%;
  }

  .info-service-cards {
    flex-direction: column;
  }

  .project-gallery {
    flex-direction: column;
    align-items: center;

  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px); /* Slight upward movement */
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Default fade-in styling for all section tags */
section {
  opacity: 0; /* Initially hidden */
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

/* Class to make the section visible with animation */
section.visible {
  opacity: 1;
  transform: translateY(0);
}

.not-found-container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f9;
  color: #333;
  text-align: center;
}

.error-code {
  font-size: 6rem;
  margin: 0;
  color: #0701F6;
}

.error-message {
  font-size: 1.5rem;
  margin: 1rem 0;
}